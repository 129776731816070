<template>
  <div>
    <UserAddEdit
      :es-id="ae.esId"
      :stepper-mode="ae.stepperMode"
      :stepper-step="ae.stepperStep"
      :user-id="ae.userId"
      :user-data="ae.userData"
    />
    <dropdown-container id="user-dropdown">
      <template v-slot:default="{ containerStyle }">
        <!-- start: updated-dd-contents -->
        <div class="fixed right-0 mt-px mr-px" :style="containerStyle">
          <ul class="p-0 m-0 bg-white shadow dd-custom-size">
            <div class="w-full px-0 py-2 py-md-3">
              <!-- start: dd-contents -->
              <div class="pb-6 border-b dd-user-metadata">
                <div class="flex items-center justify-center ">
                  <display-avatar :avatar="profileInfo.profile_pic" />
                  <!-- <img
                  class="dd-avatar"
                  src="@/assets/img/pinterest.png"
                  alt="avatar"
                /> -->
                </div>
                <div class="dd-user-full-name">{{ $user.full_name }}</div>
                <!-- <div class="mt-2 dd-user-role">{{ $user.is_superadmin }}</div> -->
                <div class="dd-user-email">{{ $user.email }}</div>
                <div class="text-sm ">
                  <router-link
                    class="font-bold text-blue-600 capitalize cursor-pointer"
                    :to="{
                      name: 'ViewOrganizationUserProfile',
                      params: { id: $user.id },
                    }"
                  >
                    View Profile
                  </router-link>
                </div>
              </div>

              <div class="pt-4 pb-6 dd-user-actions">
                <a
                  href="javascript:void(0)"
                  class="link-secondary user-action-item"
                >
                  <svg
                    class="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    ></path>
                  </svg>
                  <button class="ml-1" @click="onOpenES($user.id)">
                    Edit Profile
                  </button>
                </a>

                <router-link
                  class="mt-2 link-secondary user-action-item"
                  :to="{ name: 'UpdateUserPassword' }"
                >
                  <svg
                    class="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                    ></path>
                  </svg>
                  <span class="ml-1">Change Password</span>
                </router-link>
                <a
                  @click.prevent="logout"
                  class="mt-2 text-red-500 cursor-pointer hover:text-red-600 user-action-item"
                >
                  <svg
                    class="w-4 h-4"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                    ></path>
                  </svg>
                  <span class="ml-1">Logout</span>
                </a>
              </div>
              <!-- end: dd-contents -->
            </div>
          </ul>
        </div>
        <!-- end: updates-dd-contents -->
      </template>
    </dropdown-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import UserAddEdit from '@/views/user/organization/UserAddEdit.vue'
import DropdownContainer from '@/components/dropdown/DropdownContainer'
import DisplayAvatar from '@/components/ui/DisplayAvatar'

export default {
  components: {
    DropdownContainer,
    DisplayAvatar,
    UserAddEdit,
  },
  computed: {
    ...mapGetters('auth', ['profileInfo']),
  },
  data() {
    return {
      // AddEdit (ae)
      ae: {
        // EdgeStack component
        esId: 'user-add-edit-profile',
        // XStepper component
        stepperMode: 'free',
        stepperStep: 1,
        // Data
        data: {},
        userId: null,
        userData: null,
      },
    }
  },
  methods: {
    // EdgeStack component
    async onOpenES(userId = null) {
      if (this.$acl.canNotView('Users')) {
        return
      }
      if (userId) {
        // if userId provided, open it in free (aka edit) mode, inject user data as well
        // otherwise, open it in strict (aka add mode). In both case, inject required data,
        // but user data is required in edit (free) mode.
        this.$notify(
          {
            group: 'generic',
            type: 'default',
            title: `Processing..`,
            text: 'The action is being executed...',
          },
          600
        )
        // fetch user data
        await this.$http
          .get(this.$config.orgUser.api.single(userId))
          .then((res) => {
            this.ae.stepperMode = 'free'
            this.ae.userId = res.data.id
            this.ae.userData = res.data
            this.$edgeStack.open(this.ae.esId)

            // ** open a random step (keeping it as a reference)
            // function randomNumber(min, max) {
            //   const r = Math.random() * (max - min) + min
            //   return Math.floor(r)
            // }
            // this.$edgeStack.emitter.on(
            //   this.$edgeStack.getEventName('opened', this.ae.esId),
            //   () => {
            //     this.$xStepper.navigate(this.ae.esId).to(randomNumber(0, 4))
            //   }
            // )
          })
          .catch((err) => {
            console.warn({ err })
            this.$notify(
              {
                group: 'generic',
                type: 'error',
                title: `Action failed to execute!`,
                text: 'Failed to retrieve the user. Please try again.',
              },
              5000
            )
          })
      } else {
        // this.$xStepper.navigate(this.ae.esId).to(4)
        this.ae.stepperMode = 'strict'
        this.ae.userId = null
        this.ae.userData = null
        this.$edgeStack.open(this.ae.esId)
      }
    },
    logout: function() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push('/login')
        })
        .catch((error) => {
          // todo: show error
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
.dd-custom-size {
  width: 280px;
}

.dd-user-metadata {
  text-align: center;
}

.dd-avatar {
  width: 90px;
  height: 90px;
  border-radius: 9999px;
  object-fit: cover;
}

.dd-user-full-name {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: #323740;
}

.dd-user-role {
  font-size: 14px;
  font-weight: 700;
  text-align: center;
  color: #61666f;
}

.dd-user-email {
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  color: #8f9399;
}

.user-actions {
  list-style: none;
  text-align: left;
  padding-left: 20px;
  border-top: 1px solid #f3f3f3;
  padding-top: 15px;
  padding-bottom: 15px;
}

.user-action-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 6px 8px;
  padding: 0 10px;
}

.user-action-item:hover {
  transform: translate(12px, -2px) scale(1.09);
  transition: transform 0.15s ease-in-out;
}

// icons
.icon-lightning {
  width: 13px;
  height: 22px;
  object-fit: contain;
  // background-color: #626262;
  // Farhan: the icon wasn't centered
  margin-left: 5px;
  margin-bottom: 5px;
}

.icon-notification {
  width: 21px;
  height: 21px;
  object-fit: contain;
  // background-color: #626262;
  // Farhan: the icon wasn't centered
  // margin-left: 5px;
  margin-bottom: 5px;
}

.icon-user {
  height: 28px;
  width: 28px;
  color: #626262;
  // color: #6C6C6C;
}

.icon-menu {
  width: 21px;
  height: 21px;
  object-fit: contain;
  // background-color: #626262;
}
</style>
